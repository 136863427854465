<template>
  <div class="round-card mr-3 d-flex justify-start mouse-pointer">
    <div class="d-flex justify-start" style="width: 90%" @click="goToRoundDetailsPage()">
      <v-avatar
        tile
        left
        size="50"
        color="secondary"
        class="ma-3 round-avatar"
      >
        <span
          class="white--text text-center"
        >
          {{round.number}}°
        </span>
      </v-avatar>

      <span class="text-start mt-3" style="font-weight: bold">
        {{round.number}}° Rodada
        <v-chip small :color="round.open ? 'primary' : 'error'" class="ml-1">
          {{round.open ? 'aberta' : 'fechada'}}
        </v-chip> <br>
        <span v-if="round.open">
          Fecha em {{formatDate(round.endsAt)}}
        </span>
      </span>
    </div>

    <v-btn icon class="edit-btn" @click="update()">
      <v-icon size="20px">
        mdi-pencil
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    round: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      selected: null
    }
  },
  methods: {
    update () {
      this.$emit('updateSelected', this.round)
    },
    goToRoundDetailsPage () {
      this.$router.push(`/rodadas/editar/${this.round._id}`)
    }
  }
}
</script>

<style scoped>
.round-card {
  position: relative;
  border: 1px solid #BDBDBD !important;
  border-radius: 3px !important;
}

.round-avatar {
  border-radius: 3px !important;
}

.edit-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
